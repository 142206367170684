export enum SectoralScope {
  'Energy Industries (Renewable – / Non-Renewable Sources)' = '1',
  'Energy Distribution' = '2',
  'Energy Demand' = '3',
  'Manufacturing Industries' = '4',
  'Chemical Industries' = '5',
  'Construction' = '6',
  'Transport' = '7',
  'Mining/Mineral Production' = '8',
  'Metal Production' = '9',
  'Fugitive Emissions From Fuels (Solid, Oil and Gas)' = '10',
  'Fugitive Emissions From Production and Consumption of Halocarbons and Sulphur Hexafluoride' = '11',
  'Solvent Use' = '12',
  'Waste Handling and Disposal' = '13',
  'Afforestation and Reforestation' = '14',
  'Agriculture' = '15',
}
