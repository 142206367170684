export enum GovDepartment {
  'Cocoa Research Institute' = '1',
  'National Agricultural Extension, Research and Liaison Services' = '2',
  'National Veterinary Research Institute' = '3',
  'Agricultural Insurance Corporation' = '4',
  'National Root Crops Research Institute' = '5',
  'Agricultural Research Council' = '6',
  'Institute for Oceanography and Marine Research' = '7',
  'Institute for Oil Palm Research' = '8',
  'Agricultural Quarantine Service' = '9',
  'National Horticultural Research Institute' = '10',
  'Federal Airports Authority' = '11',
  'Airspace Management Agency' = '12',
  'Civil Aviation Authority' = '13',
  'Safety Investigation Bureau' = '14',
  'Meteorological Agency' = '15',
  'College of Aviation Technology' = '16',
  'National Information Technology Development Agency' = '17',
  'Satellite Limited' = '18',
  'Broadcasting Commission' = '19',
  'Commission' = '20',
  'Postal Service' = '21',
  'National Frequency Management Council' = '22',
  'Television Authority' = '23',
  'Galaxy Backbone' = '24',
  'Asset Management Corporation' = '25',
  'Social Security Administration' = '26',
  'Budget Office of the Federation' = '27',
  'Bureau of Public Enterprises' = '28',
  'Bureau of Public Procurement' = '29',
  'Central Bank' = '30',
  'Corporate Affairs Commission' = '31',
  'Debt Management Office' = '32',
  'Inland Revenue Service' = '33',
  'Mortgage Bank' = '34',
  'Fiscal Responsibility Commission' = '35',
  'Infrastructure Concession Regulatory Commission' = '36',
  'National Bureau of Statistics' = '37',
  'National Council on Privatisation' = '38',
  'National Insurance Commission' = '39',
  'National Pension Commission' = '40',
  'National Planning Commission' = '41',
  'National Sugar Development Council' = '42',
  'Niger Delta Development Commission' = '43',
  'Customs Service' = '44',
  'Deposit Insurance Corporation' = '45',
  'Investment Promotion Commission' = '46',
  'Export - Import Bank' = '47',
  'Export Promotion Council' = '48',
  'Oil and Gas Free Zones Authority' = '49',
  'Export Processing Zones Authority' = '50',
  'Revenue Mobilisation Allocation and Fiscal Commission' = '51',
  'Securities and Exchange Commission' = '52',
  'Standards Organisation' = '53',
  'Small and Medium Enterprise Development Agency' = '54',
  'National Board for Arabic And Islamic Studies' = '55',
  'Joint Admissions and Matriculation Board' = '56',
  'National Examination Council' = '115',
  'National Open University' = '57',
  'National Teachers Institute' = '58',
  'National Universities Commission' = '59',
  'Tertiary Education Trust Fund' = '60',
  'Teachers Registration Council' = '61',
  'National Business and Technical Examinations Board' = '62',
  'Universal Basic Education Commission' = '63',
  'West African Examination Council' = '64',
  'National Commission for Colleges of Education' = '65',
  'National Library' = '66',
  'Midstream and Downstream Petroleum Regulatory Authority' = '67',
  'Upstream Petroleum Regulatory Commission' = '68',
  'Electricity Management Services Limited' = '69',
  'Energy Commission' = '70',
  'National Power Training Institute' = '71',
  'Electricity Regulatory Commission' = '72',
  'Content Monitoring and Development Board' = '73',
  'National Petroleum Corporation' = '74',
  'Petroleum Product Pricing Regulatory Agency' = '76',
  'Power Holding Company (defunct)' = '77',
  'Rural Electrification Agency' = '78',
  'Transmission Company' = '79',
  'Environmental Protection Agency (defunct)' = '80',
  'Forestry Research Institute' = '81',
  'National Biosafety Management Agency' = '82',
  'National Environmental Standards and Regulations Enforcement Agency' = '83',
  'National Oil Spill Detection and Response Agency' = '84',
  'Environmental Health Officers Registration Council' = '85',
  'National Health Insurance Scheme' = '86',
  'Institute for Pharmaceutical Research and Development' = '87',
  'Agency for the Control of AIDS' = '88',
  'Agency for Food and Drug Administration and Control' = '89',
  'Primary Health Care Development Agency' = '90',
  'Institute of Medical Research' = '91',
  'Centre for Disease Control' = '92',
  'Drug Law Enforcement Agency' = '93',
  'Defence Intelligence Agency' = '94',
  'State Security Service' = '95',
  'National Intelligence Agency' = '96',
  'Financial Intelligence Unit' = '97',
  'National Judicial Council' = '98',
  'Federal Judicial Service Commission' = '99',
  'National Judicial Institute' = '100',
  'Maritime Administration and Safety Agency' = '101',
  'Ports Authority' = '102',
  "Shippers' Council" = '103',
  'Broadcasting Organisation' = '104',
  'News Agency' = '105',
  'Press Council' = '106',
  'Agency For Science and Engineering Infrastructure' = '108',
  'Biotechnology Development Agency' = '109',
  'Centre for Remote Sensing' = '110',
  'Science and Technology Complex' = '111',
  'Office for Technology Acquisition and Promotion' = '112',
  'Space Research and Development Agency' = '113',
  'Nuclear Regulatory Authority' = '114',
  'Raw Materials Research and Development Council' = '116',
  'Communications Satellite Ltd' = '117',
  'Centre for Technology Management' = '118',
  'Hydrological Services Agency' = '119',
  'Integrated Water Resources Commission' = '120',
  'Water Resources Institute' = '121',
  'River Basin Development Authorities' = '122',
  'Centre for Black and African Arts and Civilization' = '123',
  'Automotive Design and Development Council' = '124',
  'Code of Conduct Bureau' = '125',
  'Computer Professionals Registration Council' = '126',
  'Consumer Protection Council' = '127',
  'Economic and Financial Crimes Commission' = '128',
  'Federal Character Commission' = '129',
  'Federal Housing Authority' = '130',
  'Corrupt Practices and Other Related Offences Commission' = '131',
  'Independent National Electoral Commission' = '132',
  'Industrial Training Fund' = '133',
  'Legal Aid Council' = '134',
  'Agency for the Prohibition of Trafficking in Persons' = '135',
  'National Boundary Commission' = '136',
  'National Council of Arts and Culture' = '137',
  'Economic Reconstruction Fund' = '138',
  'Emergency Management Agency' = '139',
  'Hajj Commission' = '140',
  'Human Rights Commission' = '141',
  'Identity Management Commission' = '142',
  'Institute for Hospitality Tourism' = '143',
  'Lottery Regulatory Commission' = '144',
  'Orientation Agency' = '145',
  'Population Commission' = '146',
  'Poverty Eradication Programme (defunct)' = '147',
  'Salaries, Incomes and Wages Commission' = '148',
  'Sports Commission' = '149',
  'Extractive Industries Transparency Initiative' = '150',
  'Immigration Service' = '151',
  'Building and Road Research Institute' = '152',
  'Institute of Building' = '153',
  'Christian Pilgrim Commission' = '154',
  'Copyright Commission' = '155',
  'Tourism Development Corporation' = '156',
  'Public Complaints Commission' = '157',
  'Surveyors Council' = '158',
  'National Lottery Trust Fund' = '159',
}
