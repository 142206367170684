export enum TxType {
  CREATE = '0',
  REJECT = '1',
  ISSUE = '2',
  TRANSFER = '3',
  CERTIFY = '4',
  RETIRE = '5',
  REVOKE = '6',
  FREEZE = '7',
  AUTH = '8',
  UNFREEZE = '9',
  OWNERSHIP_UPDATE = '12',
}
