export enum Ministry {
  Agriculture = 'Agriculture',
  Aviation = 'Aviation',
  Communications = 'Communications',
  Economy = 'Economy',
  Education = 'Education',
  Energy = 'Energy',
  Environment = 'Environment',
  Health = 'Health',
  Intelligence = 'Intelligence',
  Judiciary = 'Judiciary',
  Maritime = 'Maritime',
  Media = 'Media',
  ScienceAndTechnology = 'Science And Technology',
  WaterResources = 'Water Resources',
  Other = 'Other',
}
