export enum CompanyManagementColumns {
  logo = 'logo',
  name = 'name',
  taxId = 'taxId',
  companyRole = 'companyRole',
  programmeCount = 'programmeCount',
  creditBalance = 'creditBalance',
  companyState = 'status',
  action = 'action',
}
