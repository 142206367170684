export enum ESGType {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  AR = 'A(R)',
  BBB = 'BBB',
  BB = 'BB',
  B = 'B',
  BR = 'B(R)',
  B13 = 'B13',
  B13R = 'B13(R)',
  CCC = 'CCC',
  C = 'C',
  CR = 'C(R)',
  FI = 'FI',
}
