export enum StatsCardsTypes {
  PROGRAMMES_PENDING = 'Projects Pending',
  TRANSFER_REQUEST_RECEIVED = 'Pending Transfers Received',
  PROGRAMMES_UNCERTIFIED = 'Projects Certifiable',
  TRANSFER_REQUEST_SENT = 'Pending Transfers Sent',
  PROGRAMMES_CERTIFIED = 'Projects Certified',
  CREDIT_BALANCE = 'Credit Balance',
  CREDIT_CERTIFIED = 'Credits Certified',
  PROGRAMMES = 'Programmes',
  CREDITS = 'Credits',
  CERTIFIED_CREDITS = 'Certified Credits',
  TOTAL_PROGRAMMES = 'Total Programmes',
  TOTAL_PROGRAMMES_SECTOR = 'Total Programmes: Sector',
  TOTAL_CREDITS = 'Total Credits',
  TOTAL_CREDITS_CERTIFIED = 'Total Credits Certified',
  PROGRAMME_LOCATIONS = 'Programme Locations',
  TRANSFER_LOCATIONS_INTERNATIONAL = 'Transfer Locations International',
}

export enum MrvStatsCardsTypes {
  AGG_PROGRAMME_BY_SECTOR = 'AGG_PROGRAMME_BY_SECTOR',
  // MY_AGG_PROGRAMME_BY_SECTOR = "MY_AGG_PROGRAMME_BY_SECTOR",
  AGG_NDC_ACTION_BY_TYPE = 'AGG_NDC_ACTION_BY_TYPE',
  // MY_AGG_NDC_ACTION_BY_TYPE = "MY_AGG_NDC_ACTION_BY_TYPE",
  AGG_NDC_ACTION_BY_SECTOR = 'AGG_NDC_ACTION_BY_SECTOR',
  // MY_AGG_NDC_ACTION_BY_SECTOR = "MY_AGG_NDC_ACTION_BY_SECTOR",
  TOTAL_EMISSIONS = 'TOTAL_EMISSIONS',
  // MY_TOTAL_EMISSIONS = "MY_TOTAL_EMISSIONS",
  PROGRAMME_LOCATION = 'PROGRAMME_LOCATION',
  // MY_PROGRAMME_LOCATION = "MY_PROGRAMME_LOCATION",
  INVESTMENT_LOCATION = 'INVESTMENT_LOCATION',
  // MY_INVESTMENT_LOCATION = "MY_INVESTMENT_LOCATION"
  AGG_INVESTMENT_BY_TYPE = 'AGG_INVESTMENT_BY_TYPE',
  // MY_AGG_INVESTMENT_BY_TYPE = "MY_AGG_INVESTMENT_BY_TYPE",
}

export enum SystemNames {
  CARBON_REGISTRY = 'CARBON_REGISTRY_SYSTEM',
  CARBON_TRANSPARENCY = 'CARBON_TRANSPARENCY_SYSTEM',
}

export enum GhgStatCardTypes {
  AGG_EMISSIONS_BY_SECTOR = 'AGG_EMISSIONS_BY_SECTOR',
  AGG_EMISSIONS_BY_GAS = 'AGG_EMISSIONS_BY_GAS',
  AGG_EMISSIONS_MITIGATION_POTENTIAL_BY_SECTOR = 'AGG_EMISSIONS_MITIGATION_POTENTIAL_BY_SECTOR',
  AGG_REDUCTION_PERCENT_BAU_BY_SECTOR = 'AGG_REDUCTION_PERCENT_BAU_BY_SECTOR',
  AGG_EMISSIONS_COMPARISON = 'AGG_EMISSIONS_COMPARISON',
}
