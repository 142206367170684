export enum UserManagementColumns {
  logo = 'logo',
  name = 'name',
  email = 'email',
  phoneNo = 'phoneNo',
  company = 'company',
  companyRole = 'companyRole',
  role = 'role',
  actions = 'actions',
}
