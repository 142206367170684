export enum SdgGoals {
  noPoverty = 'No Poverty',
  zeroHunger = 'Zero Hunger',
  gdHealth = 'Good Health and Well-being',
  qualityEducation = 'Quality Education',
  genderEq = 'Gender Equality',
  cleanWatr = 'Clean Water and Sanitation',
  affEnergy = 'Affordable and Clean Energy',
  decentWork = 'Decent Work and Economic Growth',
  industry = 'Industry, Innovation and Infrastructure',
  reducedInEq = 'Reduced Inequalities',
  sustainableCities = 'Sustainable Cities and Communities',
  responsibleConsumption = 'Responsible Consumption and Production',
  climateAction = 'Climate Action',
  lifeBelowWater = 'Life Below Water',
  lifeOnLand = 'Life On Land',
  peace = 'Peace, Justice and Strong Institutions',
  partnership = 'Partnerships for the Goals',
}
