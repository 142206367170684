export enum Instrument {
  LOAN = 'Loan',
  RESULT_BASED = 'ResultBased',
  GRANT = 'Grant',
  EQUITY = 'Equity',
  GUARANTEE = 'Guarantee',
  INKIND = 'In-Kind',
  CONLOAN = 'ConcessionalLoan',
  NONCONLOAN = 'Non-ConcessionalLoan',
  INSURANCE = 'Insurance',
  OTHER = 'Other',
}
