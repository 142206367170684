export enum MitigationTypes {
  AGRICULTURE = 'Agriculture',
  BIOMASS_ENERGY = 'BiomassEnergy',
  CCS = 'CCS',
  CEMENT = 'Cement',
  COAL_MINE = 'Coal/Mine',
  EE_HOUSEHOLDS = 'EEHouseholds',
  EE_INDUSTRY = 'EEIndustry',
  EE_OWN_GENERATION = 'EEOwnGeneration',
  EE_SERVICE = 'EEService',
  EE_SUPPLY_SIDE = 'EESupplySide',
  ENERGY_DISTRIBUTION = 'EnergyDistribution',
  FORESTRY = 'Forestry',
  FOSSIL_FUEL = 'FossilFuel',
  FUGITIVE = 'Fugitive',
  GEOTHERMAL = 'Geothermal',
  HFC_PFCS_SF6 = 'HFC_PFCs_SF6',
  HYDRO = 'Hydro',
  LANDFILLS = 'Landfills',
  MARINE = 'Marine',
  METHANE_AVOIDANCE = 'MethaneAvoidance',
  N20 = 'N20',
  SOLAR = 'Solar',
  TRANSPORT = 'Transport',
  WIND = 'Wind',
  CO2_USAGE = 'CO2Usage',
  TIDAL = 'Tidal',
}

export enum MitigationSubTypes {
  IRRIGATION = 'Irrigation',
  ENERGY_EFFICIENCY = 'EnergyEfficiency',
  ALTERNATIVE_FERTILISER = 'AlternativeFertilisers',
  NO_TILLAGE = 'NoTillage',
  SOIL_ENRICHMENT_BIOCHAR = 'SoilEnrichmentBiochar',
  RICE_CROPS = 'RiceCrops',

  BAGASSE_POWER = 'BagassePower',
  PALM_OIL_SOLID_WASTE = 'Palm oil solid waste',
  AGRICULTURAL_RESIDUES_OTHER_KINDS = 'AgriculturalResiduesOtherKinds',
  AGRICULTURAL_RESIDUES_RICE_HUSK = 'AgriculturalResiduesRiceHusk',
  AGRICULTURAL_RESIDUES_MUSTARD_CROP = 'AgriculturalResiduesMustardCrop',
  AGRICULTURAL_RESIDUES_POULTRY_LITTER = 'AgriculturalResiduesPoultryLitter',
  BLACK_LIQUOR = 'BlackLiquor',
  FOREST_RESIDUES_SAWMILL_WASTE = 'ForestResiduesSawmillWaste',
  FOREST_RESIDUES_OTHER = 'ForestResiduesOther',
  FOREST_BIOMASS = 'ForestBiomass',
  INDUSTRIAL_WASTE = 'IndustrialWaste',
  GASIFICATION_OF_BIOMASS = 'GasificationOfBiomass',
  SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_BIOGAS = 'SwitchFromFossilFuelToPipedBiogas',
  BIOMASS_BRIQUETTES_OR_PELLETS = 'BiomassBriquettesOrPellets',
  BIODIESEL = 'Biodiesel',
  BIODIESEL_FROM_WASTE_OIL = 'BiodieselFromWasteOil',
  ETHANOL = 'Ethanol',

  CLINKER_REPLACEMENT = 'ClinkerReplacement',

  COAL_MINE_METHANE = 'CoalMineMethane',
  COAL_BED_METHANE = 'CoalBedMethane',
  CMM_VENTILATION_AIR_METHANE = 'CMMVentilationAirMethane',
  VENTILATION_AIR_METHANE = 'VentilationAirMethane',

  LIGHTING = 'Lighting',
  SOLAR_LAMPS = 'SolarLamps',
  STOVES = 'Stoves',
  STOVES_SCHOOL_IN_GHANA = 'StovesSchoolInGhana',
  STOVES_HOUSES_IN_NAMIBIA = 'StovesHousesInNamibia',
  LIGHTING_INSULATION_SOLAR = 'LightingInsulationSolar',
  APPLIANCES = 'Appliances',

  CHEMICALS = 'Chemicals',
  PETROCHEMICALS = 'Petrochemicals',
  PAPER = 'Paper',
  CEMENT = 'Cement',
  IRON_STEEL = 'IronSteel',
  MACHINERY = 'Machinery',
  TEXTILES = 'Textiles',
  ELECTRONICS = 'Electronics',
  FOOD = 'Food',
  BUILDING_MATERIALS = 'BuildingMaterials',
  GLASS = 'Glass',
  NON_FERROUS_METALS = 'NonFerrousMetals',
  COKE_OVEN = 'CokeOven',
  Mining = 'Mining',
  CONSTRUCTION = 'Construction',
  METAL_PRODUCTS = 'MetalProducts',
  WOOD = 'Wood',
  RECYCLING = 'Recycling',

  CHEMICALS_HEAT = 'ChemicalsHeat',
  PETROCHEMICALS_HEAT = 'PetrochemicalsHeat',
  CARBON_BLACK_GAS = 'CarbonBlackGas',
  CEMENT_HEAT = 'CementHeat',
  IRON_STEEL_HEAT = 'IronSteelHeat',
  BUILDING_MATERIALS_HEAT = 'BuildingMaterialsHeat',
  GLASS_HEAT = 'GlassHeat',
  NON_FERROUS_METALS_HEAT = 'NonFerrousMetalsHeat',
  COKE_OVEN_GAS = 'CokeOvenGas',

  HVAC_LIGHTING = 'HvacLighting',
  AIR_CONDITIONING = 'AirConditioning',
  EE_NEW_BUILDINGS = 'EeNewBuildings',
  STREET_LIGHTING = 'StreetLighting',
  LIGHTING_IN_SERVICE = 'LightingInService',
  WATER_PUMPING = 'WaterPumping',
  WATER_PURIFICATION = 'WaterPurification',
  EE_PUBLIC_STOVES = 'EePublicStoves',
  EE_PUBLIC_BUILDINGS = 'EePublicBuildings',
  EE_COMMERCIAL_BUILDINGS = 'EeCommercialBuildings',

  SINGLE_CYCLE_TO_COMBINED_CYCLE = 'SingleCycleToCombinedCycle',
  COGENERATION = 'Cogeneration',
  CO_FIRING_WITH_BIOMASS = 'CoFiringWithBiomass',
  HIGHER_EFFICIENCY_COAL_POWER = 'HigherEfficiencyCoalPower',
  HIGHER_EFFICIENCY_OIL_POWER = 'HigherEfficiencyOilPower',
  HIGHER_EFFICIENCY_USING_WASTE_HEAT = 'HigherEfficiencyUsingWasteHeat',
  POWER_PLANT_REHABILITATION = 'PowerPlantRehabilitation',
  HIGHER_EFFICIENCY_STEAM_BOILER = 'HigherEfficiencySteamBoiler',

  DISTRICT_HEATING = 'DistrictHeating',
  REPLACEMENT_OF_DISTRICT_HEATING_BOILERS = 'ReplacementOfDistrictHeatingBoilers',
  CONNECTION_OF_ISOLATED_GRID = 'ConnectionOfIsolatedGrid',
  DISTRICT_COOLING = 'DistrictCooling',
  EFFICIENT_ELECTRICITY_DISTRIBUTION = 'EfficientElectricityDistribution',

  AFFORESTATION = 'Afforestation',
  MANGROVES = 'Mangroves',
  AGROFORESTRY = 'Agroforestry',
  REFORESTATION = 'Reforestation',

  COAL_TO_NATURAL_GAS = 'CoalToNaturalGas',
  COAL_TO_OIL = 'CoalToOil',
  LIGNITE_TO_NATURAL_GAS = 'LigniteToNaturalGas',
  NEW_NATURAL_GAS_PLANT = 'NewNaturalGasPlant',
  NEW_NATURAL_GAS_PLANT_USING_LNG = 'NewNaturalGasPlantUsingLng',
  OIL_TO_ELECTRICITY = 'OilToElectricity',
  OIL_TO_LPG = 'OilToLpg',
  OIL_TO_NATURAL_GAS = 'OilToNaturalGas',

  OIL_FIELD_FLARING_REDUCTION = 'OilFieldFlaringReduction',
  OIL_AND_GAS_PROCESSING_FLARING = 'OilAndGasProcessingFlaring',
  NATURAL_GAS_PIPELINES = 'NaturalGasPipelines',
  NON_HYDROCARBON_MINING = 'NonHydrocarbonMining',
  SPONTANEOUSLY_IGNITION_OF_COAL_PILES = 'SpontaneouslyIgnitionOfCoalPiles',
  CHARCOAL_PRODUCTION = 'CharcoalProduction',

  GEOTHERMAL_ELECTRICITY = 'GeothermalElectricity',
  GEOTHERMAL_HEATING = 'GeothermalHeating',

  HFC23 = 'Hfc23',
  HFC134A = 'Hfc134A',
  PFCS = 'Pfcs',
  SF6 = 'Sf6',

  RUN_OF_RIVER = 'RunOfRiver',
  EXISTING_DAM = 'ExistingDam',
  HIGHER_EFFICIENCY_HYDRO_POWER = 'HigherEfficiencyHydroPower',
  NEW_DAM = 'NewDam',

  LANDFILL_FLARING = 'LandfillFlaring',
  LANDFILL_POWER = 'LandfillPower',
  COMBUSTION_OF_MSW = 'CombustionOfMsw',
  GASIFICATION_OF_MSW = 'GasificationOfMsw',
  BIOGAS_FROM_MSW = 'BiogasFromMsw',
  LANDFILL_AERATION = 'LandfillAeration',
  INTEGRATED_SOLID_WASTE_MANAGEMENT = 'IntegratedSolidWasteManagement',
  SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_LANDFILL_GAS = 'SwitchFromFossilFuelToPipedLandfillGas',
  LANDFILL_COMPOSTING = 'LandfillComposting',

  MANURE = 'Manure',
  DOMESTIC_MANURE = 'DomesticManure',
  WASTE_WATER = 'WasteWater',
  INDUSTRIAL_SOLID_WASTE = 'IndustrialSolidWaste',
  PALM_OIL_WASTE = 'PalmOilWaste',
  AEROBIC_TREATMENT_OF_WASTE_WATER = 'AerobicTreatmentOfWasteWater',
  COMPOSTING = 'Composting',

  ADIPIC_ACID = 'AdipicAcid',
  NITRIC_ACID = 'NitricAcid',
  CAPROLACTAM = 'Caprolactam',

  SOLAR_PHOTOVOLTAICS_PV = 'SolarPhotovoltaicsPV',
  SOLAR_WATER_PUMPING_OFF_GRID = 'SolarWaterPumpingOffGrid',
  SOLAR_WATER_PUMPING_ON_GRID = 'SolarWaterPumpingOnGrid',
  SOLAR_PV_WATER_DISINFECTION = 'SolarPVWaterDisinfection',
  SOLAR_THERMAL_POWER = 'SolarThermalPower',
  SOLAR_THERMAL_HEAT = 'SolarThermalHeat',
  SOLAR_WATER_HEATING = 'SolarWaterHeating',
  SOLAR_COOKING = 'SolarCooking',

  BUS_RAPID_TRANSIT = 'BusRapidTransit',
  BUS_RAPID_TRANSIT_GREEN_HYDROGEN = 'BusRapidTransitGreenHydrogen',
  MOTORBIKES = 'Motorbikes',
  MODE_SHIFT_ROAD_TO_RAIL = 'ModeShiftRoadToRail',
  MORE_EFFICIENT_TRAIN_SYSTEM = 'MoreEfficientTrainSystem',
  MORE_EFFICIENT_VEHICLES = 'MoreEfficientVehicles',
  RAIL_REGENERATIVE_BRAKING = 'RailRegenerativeBraking',
  RAIL_GREEN_HYDROGEN = 'RailGreenHydrogen',
  RAIL_EFFICIENT_OPERATION = 'RailEfficientOperation',
  METRO_EFFICIENT_OPERATION = 'MetroEfficientOperation',
  CARS_GREEN_HYDROGEN = 'CarsGreenHydrogen',
  CARS_EFFICIENT_OPERATION = 'CarsEfficientOperation',
  SCRAPPING_OLD_VEHICLES = 'ScrappingOldVehicles',
  BIODIESEL_FOR_TRANSPORT = 'BiodieselForTransport',
  CABLE_CARS = 'CableCars',

  WIND = 'Wind',
  OFFSHORE_WIND = 'OffshoreWind',

  CO2_RECYCLING = 'CO2Recycling',
  CO2_REPLACEMENT = 'CO2Replacement',

  TIDAL = 'Tidal',
}

export const mitigationTypeList = [
  { value: MitigationTypes.AGRICULTURE.valueOf(), label: 'Agriculture' },
  { value: MitigationTypes.SOLAR.valueOf(), label: 'Solar' },
  { value: MitigationTypes.BIOMASS_ENERGY.valueOf(), label: 'Biomass energy' },
  { value: MitigationTypes.CCS.valueOf(), label: 'CCS' },
  { value: MitigationTypes.CEMENT.valueOf(), label: 'Cement' },
  {
    value: MitigationTypes.COAL_MINE.valueOf(),
    label: 'Coal bed/mine methane',
  },
  { value: MitigationTypes.EE_HOUSEHOLDS.valueOf(), label: 'EE households' },
  { value: MitigationTypes.EE_INDUSTRY.valueOf(), label: 'EE industry' },
  {
    value: MitigationTypes.EE_OWN_GENERATION.valueOf(),
    label: 'EE own generation',
  },
  { value: MitigationTypes.EE_SERVICE.valueOf(), label: 'EE service' },
  { value: MitigationTypes.EE_SUPPLY_SIDE.valueOf(), label: 'EE supply side' },
  {
    value: MitigationTypes.ENERGY_DISTRIBUTION.valueOf(),
    label: 'Energy distribution',
  },
  { value: MitigationTypes.FORESTRY.valueOf(), label: 'Forestry' },
  {
    value: MitigationTypes.FOSSIL_FUEL.valueOf(),
    label: 'Fossil fuel switch',
  },
  { value: MitigationTypes.FUGITIVE.valueOf(), label: 'Fugitive' },
  { value: MitigationTypes.GEOTHERMAL.valueOf(), label: 'Geothermal' },
  { value: MitigationTypes.HFC_PFCS_SF6.valueOf(), label: 'HFCs, PFCs, SF6' },
  { value: MitigationTypes.HYDRO.valueOf(), label: 'Hydro' },
  { value: MitigationTypes.LANDFILLS.valueOf(), label: 'Landfills' },
  { value: MitigationTypes.MARINE.valueOf(), label: 'Marine' },
  {
    value: MitigationTypes.METHANE_AVOIDANCE.valueOf(),
    label: 'Methane avoidance',
  },
  { value: MitigationTypes.N20.valueOf(), label: 'N2O' },
  { value: MitigationTypes.TRANSPORT.valueOf(), label: 'Transport' },
  { value: MitigationTypes.WIND.valueOf(), label: 'Wind' },
  { value: MitigationTypes.CO2_USAGE.valueOf(), label: 'CO2 usage' },
  { value: MitigationTypes.TIDAL.valueOf(), label: 'Tidal' },
];

export const sectorMitigationTypesListMapped: any = {
  Energy: [
    {
      value: MitigationTypes.BIOMASS_ENERGY.valueOf(),
      label: 'Biomass energy',
    },
    {
      value: MitigationTypes.COAL_MINE.valueOf(),
      label: 'Coal bed/mine methane',
    },
    { value: MitigationTypes.EE_HOUSEHOLDS.valueOf(), label: 'EE households' },
    { value: MitigationTypes.EE_INDUSTRY.valueOf(), label: 'EE industry' },
    {
      value: MitigationTypes.EE_OWN_GENERATION.valueOf(),
      label: 'EE own generation',
    },
    { value: MitigationTypes.EE_SERVICE.valueOf(), label: 'EE service' },
    {
      value: MitigationTypes.EE_SUPPLY_SIDE.valueOf(),
      label: 'EE supply side',
    },
    {
      value: MitigationTypes.ENERGY_DISTRIBUTION.valueOf(),
      label: 'Energy distribution',
    },
    {
      value: MitigationTypes.FOSSIL_FUEL.valueOf(),
      label: 'Fossil fuel switch',
    },
    { value: MitigationTypes.GEOTHERMAL.valueOf(), label: 'Geothermal' },
    { value: MitigationTypes.HYDRO.valueOf(), label: 'Hydro' },
    { value: MitigationTypes.SOLAR.valueOf(), label: 'Solar' },
    { value: MitigationTypes.WIND.valueOf(), label: 'Wind' },
  ],
  Health: [],
  Education: [],
  Transport: [{ value: MitigationTypes.TRANSPORT.valueOf(), label: 'Transport' }],
  Manufacturing: [
    { value: MitigationTypes.CEMENT.valueOf(), label: 'Cement' },
    { value: MitigationTypes.CCS.valueOf(), label: 'CCS' },
    { value: MitigationTypes.HFC_PFCS_SF6.valueOf(), label: 'HFCs, PFCs, SF6' },
  ],
  Hospitality: [],
  Forestry: [{ value: MitigationTypes.FORESTRY.valueOf(), label: 'Forestry' }],
  Waste: [
    { value: MitigationTypes.LANDFILLS.valueOf(), label: 'Landfills' },
    { value: MitigationTypes.FUGITIVE.valueOf(), label: 'Fugitive' },
    {
      value: MitigationTypes.METHANE_AVOIDANCE.valueOf(),
      label: 'Methane avoidance',
    },
  ],
  Agriculture: [{ value: MitigationTypes.AGRICULTURE.valueOf(), label: 'Agriculture' }],
  Other: [
    { value: MitigationTypes.MARINE.valueOf(), label: 'Marine' },
    { value: MitigationTypes.N20.valueOf(), label: 'N2O' },
    { value: MitigationTypes.CO2_USAGE.valueOf(), label: 'CO2 usage' },
    { value: MitigationTypes.TIDAL.valueOf(), label: 'Tidal' },
  ],
};

export const mitigationSubTypeList = [
  { label: 'Irrigation', value: MitigationSubTypes.IRRIGATION.valueOf() },
  { label: 'Energy efficiency', value: MitigationSubTypes.ENERGY_EFFICIENCY.valueOf() },
  { label: 'Alternative fertilisers', value: MitigationSubTypes.ALTERNATIVE_FERTILISER.valueOf() },
  { label: 'No tillage', value: MitigationSubTypes.NO_TILLAGE.valueOf() },
  {
    label: 'Soil Enrichment / Biochar',
    value: MitigationSubTypes.SOIL_ENRICHMENT_BIOCHAR.valueOf(),
  },
  { label: 'Rice crops', value: MitigationSubTypes.RICE_CROPS.valueOf() },
  { label: 'Bagasse power', value: MitigationSubTypes.BAGASSE_POWER.valueOf() },
  { label: 'Palm oil solid waste', value: MitigationSubTypes.PALM_OIL_SOLID_WASTE.valueOf() },
  {
    label: 'Agricultural residues: other kinds',
    value: MitigationSubTypes.AGRICULTURAL_RESIDUES_OTHER_KINDS.valueOf(),
  },
  {
    label: 'Agricultural residues: rice husk',
    value: MitigationSubTypes.AGRICULTURAL_RESIDUES_RICE_HUSK.valueOf(),
  },
  {
    label: 'Agricultural residues: mustard crop',
    value: MitigationSubTypes.AGRICULTURAL_RESIDUES_MUSTARD_CROP.valueOf(),
  },
  {
    label: 'Agricultural residues: poultry litter',
    value: MitigationSubTypes.AGRICULTURAL_RESIDUES_POULTRY_LITTER.valueOf(),
  },
  { label: 'Black liquor', value: MitigationSubTypes.BLACK_LIQUOR.valueOf() },
  {
    label: 'Forest residues: sawmill waste',
    value: MitigationSubTypes.FOREST_RESIDUES_SAWMILL_WASTE.valueOf(),
  },
  { label: 'Forest residues: other', value: MitigationSubTypes.FOREST_RESIDUES_OTHER.valueOf() },
  { label: 'Forest biomass', value: MitigationSubTypes.FOREST_BIOMASS.valueOf() },
  { label: 'Industrial waste', value: MitigationSubTypes.INDUSTRIAL_WASTE.valueOf() },
  { label: 'Gasification of biomass', value: MitigationSubTypes.GASIFICATION_OF_BIOMASS.valueOf() },
  {
    label: 'Switch from fossil fuel to piped biogas',
    value: MitigationSubTypes.SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_BIOGAS.valueOf(),
  },
  {
    label: 'Biomass briquettes or pellets',
    value: MitigationSubTypes.BIOMASS_BRIQUETTES_OR_PELLETS.valueOf(),
  },
  { label: 'Biodiesel', value: MitigationSubTypes.BIODIESEL.valueOf() },
  {
    label: 'Biodiesel from waste oil',
    value: MitigationSubTypes.BIODIESEL_FROM_WASTE_OIL.valueOf(),
  },
  { label: 'Ethanol', value: MitigationSubTypes.ETHANOL.valueOf() },
  { label: 'Clinker replacement', value: MitigationSubTypes.CLINKER_REPLACEMENT.valueOf() },
  { label: 'Coal Mine Methane', value: MitigationSubTypes.COAL_MINE_METHANE.valueOf() },
  { label: 'Coal Bed Methane', value: MitigationSubTypes.COAL_BED_METHANE.valueOf() },
  {
    label: 'CMM & Ventilation Air Methane',
    value: MitigationSubTypes.CMM_VENTILATION_AIR_METHANE.valueOf(),
  },
  { label: 'Ventilation Air Methane', value: MitigationSubTypes.VENTILATION_AIR_METHANE.valueOf() },
  { label: 'Lighting', value: MitigationSubTypes.LIGHTING.valueOf() },
  { label: 'Solar lamps', value: MitigationSubTypes.SOLAR_LAMPS.valueOf() },
  { label: 'Stoves', value: MitigationSubTypes.STOVES.valueOf() },
  { label: 'Stoves (School in Ghana)', value: MitigationSubTypes.STOVES_SCHOOL_IN_GHANA.valueOf() },
  {
    label: 'Stoves (Houses in Namibia)',
    value: MitigationSubTypes.STOVES_HOUSES_IN_NAMIBIA.valueOf(),
  },
  {
    label: 'Lighting & Insulation & Solar',
    value: MitigationSubTypes.LIGHTING_INSULATION_SOLAR.valueOf(),
  },
  { label: 'Appliances', value: MitigationSubTypes.APPLIANCES.valueOf() },
  { label: 'Chemicals', value: MitigationSubTypes.CHEMICALS.valueOf() },
  { label: 'Petrochemicals', value: MitigationSubTypes.PETROCHEMICALS.valueOf() },
  { label: 'Paper', value: MitigationSubTypes.PAPER.valueOf() },
  { label: 'Cement', value: MitigationSubTypes.CEMENT.valueOf() },
  { label: 'Iron & steel ', value: MitigationSubTypes.IRON_STEEL.valueOf() },
  { label: 'Machinery', value: MitigationSubTypes.MACHINERY.valueOf() },
  { label: 'Textiles', value: MitigationSubTypes.TEXTILES.valueOf() },
  { label: 'Electronics', value: MitigationSubTypes.ELECTRONICS.valueOf() },
  { label: 'Food', value: MitigationSubTypes.FOOD.valueOf() },
  { label: 'Building materials', value: MitigationSubTypes.BUILDING_MATERIALS.valueOf() },
  { label: 'Glass', value: MitigationSubTypes.GLASS.valueOf() },
  { label: 'Non-ferrous metals', value: MitigationSubTypes.NON_FERROUS_METALS.valueOf() },
  { label: 'Coke oven', value: MitigationSubTypes.COKE_OVEN.valueOf() },
  { label: 'Mining', value: MitigationSubTypes.Mining.valueOf() },
  { label: 'Construction', value: MitigationSubTypes.CONSTRUCTION.valueOf() },
  { label: 'Metal products', value: MitigationSubTypes.METAL_PRODUCTS.valueOf() },
  { label: 'Wood', value: MitigationSubTypes.WOOD.valueOf() },
  { label: 'Recycling', value: MitigationSubTypes.RECYCLING.valueOf() },
  { label: 'Chemicals heat', value: MitigationSubTypes.CHEMICALS_HEAT.valueOf() },
  { label: 'Petrochemicals heat', value: MitigationSubTypes.PETROCHEMICALS_HEAT.valueOf() },
  { label: 'Carbon black gas', value: MitigationSubTypes.CARBON_BLACK_GAS.valueOf() },
  { label: 'Cement heat', value: MitigationSubTypes.CEMENT_HEAT.valueOf() },
  { label: 'Iron & steel heat', value: MitigationSubTypes.IRON_STEEL_HEAT.valueOf() },
  { label: 'Building materials heat', value: MitigationSubTypes.BUILDING_MATERIALS_HEAT.valueOf() },
  { label: 'Glass heat', value: MitigationSubTypes.GLASS_HEAT.valueOf() },
  { label: 'Non-ferrous metals heat', value: MitigationSubTypes.NON_FERROUS_METALS_HEAT.valueOf() },
  { label: 'Coke oven gas', value: MitigationSubTypes.COKE_OVEN_GAS.valueOf() },
  { label: 'HVAC & lighting', value: MitigationSubTypes.HVAC_LIGHTING.valueOf() },
  { label: 'Air conditioning', value: MitigationSubTypes.AIR_CONDITIONING.valueOf() },
  { label: 'EE new buildings', value: MitigationSubTypes.EE_NEW_BUILDINGS.valueOf() },
  { label: 'Street lighting', value: MitigationSubTypes.STREET_LIGHTING.valueOf() },
  { label: 'Lighting in service', value: MitigationSubTypes.LIGHTING_IN_SERVICE.valueOf() },
  { label: 'Water pumping', value: MitigationSubTypes.WATER_PUMPING.valueOf() },
  { label: 'Water purification', value: MitigationSubTypes.WATER_PURIFICATION.valueOf() },
  { label: 'EE public stoves', value: MitigationSubTypes.EE_PUBLIC_STOVES.valueOf() },
  { label: 'EE public buildings', value: MitigationSubTypes.EE_PUBLIC_BUILDINGS.valueOf() },
  { label: 'EE commercial buildings', value: MitigationSubTypes.EE_COMMERCIAL_BUILDINGS.valueOf() },
  {
    label: 'Single cycle to combined cycle',
    value: MitigationSubTypes.SINGLE_CYCLE_TO_COMBINED_CYCLE.valueOf(),
  },
  { label: 'Cogeneration', value: MitigationSubTypes.COGENERATION.valueOf() },
  { label: 'Co-firing with biomass', value: MitigationSubTypes.CO_FIRING_WITH_BIOMASS.valueOf() },
  {
    label: 'Higher efficiency coal power',
    value: MitigationSubTypes.HIGHER_EFFICIENCY_COAL_POWER.valueOf(),
  },
  {
    label: 'Higher efficiency oil power',
    value: MitigationSubTypes.HIGHER_EFFICIENCY_OIL_POWER.valueOf(),
  },
  {
    label: 'Higher efficiency using waste heat',
    value: MitigationSubTypes.HIGHER_EFFICIENCY_USING_WASTE_HEAT.valueOf(),
  },
  {
    label: 'Power plant rehabilitation',
    value: MitigationSubTypes.POWER_PLANT_REHABILITATION.valueOf(),
  },
  {
    label: 'Higher efficiency steam boiler',
    value: MitigationSubTypes.HIGHER_EFFICIENCY_STEAM_BOILER.valueOf(),
  },
  { label: 'District heating', value: MitigationSubTypes.DISTRICT_HEATING.valueOf() },
  {
    label: 'Replacement of district heating boilers',
    value: MitigationSubTypes.REPLACEMENT_OF_DISTRICT_HEATING_BOILERS.valueOf(),
  },
  {
    label: 'Connection of Isolated grid',
    value: MitigationSubTypes.CONNECTION_OF_ISOLATED_GRID.valueOf(),
  },
  { label: 'District cooling', value: MitigationSubTypes.DISTRICT_COOLING.valueOf() },
  {
    label: 'Efficient electricity distribution',
    value: MitigationSubTypes.EFFICIENT_ELECTRICITY_DISTRIBUTION.valueOf(),
  },
  { label: 'Afforestation', value: MitigationSubTypes.AFFORESTATION.valueOf() },
  { label: 'Mangroves', value: MitigationSubTypes.MANGROVES.valueOf() },
  { label: 'Agroforestry', value: MitigationSubTypes.AGROFORESTRY.valueOf() },
  { label: 'Reforestation', value: MitigationSubTypes.REFORESTATION.valueOf() },
  { label: 'Coal to natural gas', value: MitigationSubTypes.COAL_TO_NATURAL_GAS.valueOf() },
  { label: 'Coal to oil', value: MitigationSubTypes.COAL_TO_OIL.valueOf() },
  { label: 'Lignite to natural gas', value: MitigationSubTypes.LIGNITE_TO_NATURAL_GAS.valueOf() },
  { label: 'New natural gas plant', value: MitigationSubTypes.NEW_NATURAL_GAS_PLANT.valueOf() },
  {
    label: 'New natural gas plant using LNG',
    value: MitigationSubTypes.NEW_NATURAL_GAS_PLANT_USING_LNG.valueOf(),
  },
  { label: 'Oil to electricity', value: MitigationSubTypes.OIL_TO_ELECTRICITY.valueOf() },
  { label: 'Oil to LPG', value: MitigationSubTypes.OIL_TO_LPG.valueOf() },
  { label: 'Oil to natural gas', value: MitigationSubTypes.OIL_TO_NATURAL_GAS.valueOf() },
  {
    label: 'Oil field flaring reduction',
    value: MitigationSubTypes.OIL_FIELD_FLARING_REDUCTION.valueOf(),
  },
  {
    label: 'Oil and gas processing flaring',
    value: MitigationSubTypes.OIL_AND_GAS_PROCESSING_FLARING.valueOf(),
  },
  { label: 'Natural gas pipelines', value: MitigationSubTypes.NATURAL_GAS_PIPELINES.valueOf() },
  { label: 'Non-hydrocarbon mining', value: MitigationSubTypes.NON_HYDROCARBON_MINING.valueOf() },
  {
    label: 'Spontaneously ignition of coal piles',
    value: MitigationSubTypes.SPONTANEOUSLY_IGNITION_OF_COAL_PILES.valueOf(),
  },
  { label: 'Charcoal production', value: MitigationSubTypes.CHARCOAL_PRODUCTION.valueOf() },
  { label: 'Geothermal electricity', value: MitigationSubTypes.GEOTHERMAL_ELECTRICITY.valueOf() },
  { label: 'Geothermal heating', value: MitigationSubTypes.GEOTHERMAL_HEATING.valueOf() },
  { label: 'HFC23', value: MitigationSubTypes.HFC23.valueOf() },
  { label: 'HFC134a', value: MitigationSubTypes.HFC134A.valueOf() },
  { label: 'PFCs', value: MitigationSubTypes.PFCS.valueOf() },
  { label: 'SF6', value: MitigationSubTypes.SF6.valueOf() },
  { label: 'Run of river', value: MitigationSubTypes.RUN_OF_RIVER.valueOf() },
  { label: 'Existing dam', value: MitigationSubTypes.EXISTING_DAM.valueOf() },
  {
    label: 'Higher efficiency hydro power',
    value: MitigationSubTypes.HIGHER_EFFICIENCY_HYDRO_POWER.valueOf(),
  },
  { label: 'New dam', value: MitigationSubTypes.NEW_DAM.valueOf() },
  { label: 'Landfill flaring', value: MitigationSubTypes.LANDFILL_FLARING.valueOf() },
  { label: 'Landfill power', value: MitigationSubTypes.LANDFILL_POWER.valueOf() },
  { label: 'Combustion of MSW', value: MitigationSubTypes.COMBUSTION_OF_MSW.valueOf() },
  { label: 'Gasification of MSW', value: MitigationSubTypes.GASIFICATION_OF_MSW.valueOf() },
  { label: 'Biogas from MSW', value: MitigationSubTypes.BIOGAS_FROM_MSW.valueOf() },
  { label: 'Landfill aeration', value: MitigationSubTypes.LANDFILL_AERATION.valueOf() },
  {
    label: 'Integrated solid waste management',
    value: MitigationSubTypes.INTEGRATED_SOLID_WASTE_MANAGEMENT.valueOf(),
  },
  {
    label: 'Switch from fossil fuel to piped landfill gas',
    value: MitigationSubTypes.SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_LANDFILL_GAS.valueOf(),
  },
  { label: 'Landfill composting', value: MitigationSubTypes.LANDFILL_COMPOSTING.valueOf() },
  { label: 'Manure', value: MitigationSubTypes.MANURE.valueOf() },
  { label: 'Domestic manure', value: MitigationSubTypes.DOMESTIC_MANURE.valueOf() },
  { label: 'Waste water', value: MitigationSubTypes.WASTE_WATER.valueOf() },
  { label: 'Industrial solid waste', value: MitigationSubTypes.INDUSTRIAL_SOLID_WASTE.valueOf() },
  { label: 'Palm oil waste', value: MitigationSubTypes.PALM_OIL_WASTE.valueOf() },
  {
    label: 'Aerobic treatment of waste water',
    value: MitigationSubTypes.AEROBIC_TREATMENT_OF_WASTE_WATER.valueOf(),
  },
  { label: 'Composting', value: MitigationSubTypes.COMPOSTING.valueOf() },
  { label: 'Adipic acid', value: MitigationSubTypes.ADIPIC_ACID.valueOf() },
  { label: 'Nitric acid', value: MitigationSubTypes.NITRIC_ACID.valueOf() },
  { label: 'Caprolactam', value: MitigationSubTypes.CAPROLACTAM.valueOf() },
  { label: 'Solar Photovoltaics(PV)', value: MitigationSubTypes.SOLAR_PHOTOVOLTAICS_PV.valueOf() },
  {
    label: 'Solar Water pumping (off-grid)',
    value: MitigationSubTypes.SOLAR_WATER_PUMPING_OFF_GRID.valueOf(),
  },
  {
    label: 'Solar Water pumping (on-grid)',
    value: MitigationSubTypes.SOLAR_WATER_PUMPING_ON_GRID.valueOf(),
  },
  {
    label: 'Solar PV water disinfection',
    value: MitigationSubTypes.SOLAR_PV_WATER_DISINFECTION.valueOf(),
  },
  { label: 'Solar thermal power', value: MitigationSubTypes.SOLAR_THERMAL_POWER.valueOf() },
  { label: 'Solar thermal heat', value: MitigationSubTypes.SOLAR_THERMAL_HEAT.valueOf() },
  { label: 'Solar water heating', value: MitigationSubTypes.SOLAR_WATER_HEATING.valueOf() },
  { label: 'Solar cooking', value: MitigationSubTypes.SOLAR_COOKING.valueOf() },
  { label: 'Bus Rapid Transit', value: MitigationSubTypes.BUS_RAPID_TRANSIT.valueOf() },
  {
    label: 'Bus Rapid Transit: Green hydrogen',
    value: MitigationSubTypes.BUS_RAPID_TRANSIT_GREEN_HYDROGEN.valueOf(),
  },
  { label: 'Motorbikes', value: MitigationSubTypes.MOTORBIKES.valueOf() },
  {
    label: 'Mode shift: Road to rail',
    value: MitigationSubTypes.MODE_SHIFT_ROAD_TO_RAIL.valueOf(),
  },
  {
    label: 'More efficient train system',
    value: MitigationSubTypes.MORE_EFFICIENT_VEHICLES.valueOf(),
  },
  { label: 'More efficient vehicles', value: MitigationSubTypes.MORE_EFFICIENT_VEHICLES.valueOf() },
  {
    label: 'Rail: regenerative braking',
    value: MitigationSubTypes.RAIL_REGENERATIVE_BRAKING.valueOf(),
  },
  { label: 'Rail: Green hydrogen', value: MitigationSubTypes.RAIL_GREEN_HYDROGEN.valueOf() },
  {
    label: 'Rail: efficient operation',
    value: MitigationSubTypes.RAIL_EFFICIENT_OPERATION.valueOf(),
  },
  {
    label: 'Metro: efficient operation',
    value: MitigationSubTypes.METRO_EFFICIENT_OPERATION.valueOf(),
  },
  { label: 'Cars: Green hydrogen', value: MitigationSubTypes.CARS_GREEN_HYDROGEN.valueOf() },
  {
    label: 'Cars: efficient operation',
    value: MitigationSubTypes.CARS_EFFICIENT_OPERATION.valueOf(),
  },
  { label: 'Scrapping old vehicles', value: MitigationSubTypes.SCRAPPING_OLD_VEHICLES.valueOf() },
  { label: 'Biodiesel for transport', value: MitigationSubTypes.BIODIESEL_FOR_TRANSPORT.valueOf() },
  { label: 'Cable cars', value: MitigationSubTypes.CABLE_CARS.valueOf() },
  { label: 'Wind', value: MitigationSubTypes.WIND.valueOf() },
  { label: 'Offshore wind', value: MitigationSubTypes.OFFSHORE_WIND.valueOf() },
  { label: 'CO2 recycling', value: MitigationSubTypes.CO2_RECYCLING.valueOf() },
  { label: 'CO2 replacement', value: MitigationSubTypes.CO2_REPLACEMENT.valueOf() },
  { label: 'Tidal', value: MitigationSubTypes.TIDAL.valueOf() },
];

export const mitigationSubTypesListMapped: any = {
  Agriculture: [
    { label: 'Irrigation', value: MitigationSubTypes.IRRIGATION.valueOf() },
    { label: 'Energy efficiency', value: MitigationSubTypes.ENERGY_EFFICIENCY.valueOf() },
    {
      label: 'Alternative fertilisers',
      value: MitigationSubTypes.ALTERNATIVE_FERTILISER.valueOf(),
    },
    { label: 'No tillage', value: MitigationSubTypes.NO_TILLAGE.valueOf() },
    {
      label: 'Soil Enrichment / Biochar',
      value: MitigationSubTypes.SOIL_ENRICHMENT_BIOCHAR.valueOf(),
    },
    { label: 'Rice crops', value: MitigationSubTypes.RICE_CROPS.valueOf() },
  ],
  BiomassEnergy: [
    { label: 'Bagasse power', value: MitigationSubTypes.BAGASSE_POWER.valueOf() },
    { label: 'Palm oil solid waste', value: MitigationSubTypes.PALM_OIL_SOLID_WASTE.valueOf() },
    {
      label: 'Agricultural residues: other kinds',
      value: MitigationSubTypes.AGRICULTURAL_RESIDUES_OTHER_KINDS.valueOf(),
    },
    {
      label: 'Agricultural residues: rice husk',
      value: MitigationSubTypes.AGRICULTURAL_RESIDUES_RICE_HUSK.valueOf(),
    },
    {
      label: 'Agricultural residues: mustard crop',
      value: MitigationSubTypes.AGRICULTURAL_RESIDUES_MUSTARD_CROP.valueOf(),
    },
    {
      label: 'Agricultural residues: poultry litter',
      value: MitigationSubTypes.AGRICULTURAL_RESIDUES_POULTRY_LITTER.valueOf(),
    },
    { label: 'Black liquor', value: MitigationSubTypes.BLACK_LIQUOR.valueOf() },
    {
      label: 'Forest residues: sawmill waste',
      value: MitigationSubTypes.FOREST_RESIDUES_SAWMILL_WASTE.valueOf(),
    },
    { label: 'Forest residues: other', value: MitigationSubTypes.FOREST_RESIDUES_OTHER.valueOf() },
    { label: 'Forest biomass', value: MitigationSubTypes.FOREST_BIOMASS.valueOf() },
    { label: 'Industrial waste', value: MitigationSubTypes.INDUSTRIAL_WASTE.valueOf() },
    {
      label: 'Gasification of biomass',
      value: MitigationSubTypes.GASIFICATION_OF_BIOMASS.valueOf(),
    },
    {
      label: 'Switch from fossil fuel to piped biogas',
      value: MitigationSubTypes.SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_BIOGAS.valueOf(),
    },
    {
      label: 'Biomass briquettes or pellets',
      value: MitigationSubTypes.BIOMASS_BRIQUETTES_OR_PELLETS.valueOf(),
    },
    { label: 'Biodiesel', value: MitigationSubTypes.BIODIESEL.valueOf() },
    {
      label: 'Biodiesel from waste oil',
      value: MitigationSubTypes.BIODIESEL_FROM_WASTE_OIL.valueOf(),
    },
    { label: 'Ethanol', value: MitigationSubTypes.ETHANOL.valueOf() },
  ],
  Cement: [
    { label: 'Clinker replacement', value: MitigationSubTypes.CLINKER_REPLACEMENT.valueOf() },
  ],
  'Coal/Mine': [
    { label: 'Coal Mine Methane', value: MitigationSubTypes.COAL_MINE_METHANE.valueOf() },
    { label: 'Coal Bed Methane', value: MitigationSubTypes.COAL_BED_METHANE.valueOf() },
    {
      label: 'CMM & Ventilation Air Methane',
      value: MitigationSubTypes.CMM_VENTILATION_AIR_METHANE.valueOf(),
    },
    {
      label: 'Ventilation Air Methane',
      value: MitigationSubTypes.VENTILATION_AIR_METHANE.valueOf(),
    },
  ],
  EEHouseholds: [
    { label: 'Lighting', value: MitigationSubTypes.LIGHTING.valueOf() },
    { label: 'Solar lamps', value: MitigationSubTypes.SOLAR_LAMPS.valueOf() },
    { label: 'Stoves', value: MitigationSubTypes.STOVES.valueOf() },
    {
      label: 'Stoves (School in Ghana)',
      value: MitigationSubTypes.STOVES_SCHOOL_IN_GHANA.valueOf(),
    },
    {
      label: 'Stoves (Houses in Namibia)',
      value: MitigationSubTypes.STOVES_HOUSES_IN_NAMIBIA.valueOf(),
    },
    {
      label: 'Lighting & Insulation & Solar',
      value: MitigationSubTypes.LIGHTING_INSULATION_SOLAR.valueOf(),
    },
    { label: 'Appliances', value: MitigationSubTypes.APPLIANCES.valueOf() },
  ],
  EEIndustry: [
    { label: 'Chemicals', value: MitigationSubTypes.CHEMICALS.valueOf() },
    { label: 'Petrochemicals', value: MitigationSubTypes.PETROCHEMICALS.valueOf() },
    { label: 'Paper', value: MitigationSubTypes.PAPER.valueOf() },
    { label: 'Cement', value: MitigationSubTypes.CEMENT.valueOf() },
    { label: 'Iron & steel ', value: MitigationSubTypes.IRON_STEEL.valueOf() },
    { label: 'Machinery', value: MitigationSubTypes.MACHINERY.valueOf() },
    { label: 'Textiles', value: MitigationSubTypes.TEXTILES.valueOf() },
    { label: 'Electronics', value: MitigationSubTypes.ELECTRONICS.valueOf() },
    { label: 'Food', value: MitigationSubTypes.FOOD.valueOf() },
    { label: 'Building materials', value: MitigationSubTypes.BUILDING_MATERIALS.valueOf() },
    { label: 'Glass', value: MitigationSubTypes.GLASS.valueOf() },
    { label: 'Non-ferrous metals', value: MitigationSubTypes.NON_FERROUS_METALS.valueOf() },
    { label: 'Coke oven', value: MitigationSubTypes.COKE_OVEN.valueOf() },
    { label: 'Mining', value: MitigationSubTypes.Mining.valueOf() },
    { label: 'Construction', value: MitigationSubTypes.CONSTRUCTION.valueOf() },
    { label: 'Metal products', value: MitigationSubTypes.METAL_PRODUCTS.valueOf() },
    { label: 'Wood', value: MitigationSubTypes.WOOD.valueOf() },
    { label: 'Recycling', value: MitigationSubTypes.RECYCLING.valueOf() },
  ],
  EEOwnGeneration: [
    { label: 'Chemicals heat', value: MitigationSubTypes.CHEMICALS_HEAT.valueOf() },
    { label: 'Petrochemicals heat', value: MitigationSubTypes.PETROCHEMICALS_HEAT.valueOf() },
    { label: 'Carbon black gas', value: MitigationSubTypes.CARBON_BLACK_GAS.valueOf() },
    { label: 'Cement heat', value: MitigationSubTypes.CEMENT_HEAT.valueOf() },
    { label: 'Iron & steel heat', value: MitigationSubTypes.IRON_STEEL_HEAT.valueOf() },
    {
      label: 'Building materials heat',
      value: MitigationSubTypes.BUILDING_MATERIALS_HEAT.valueOf(),
    },
    { label: 'Glass heat', value: MitigationSubTypes.GLASS_HEAT.valueOf() },
    {
      label: 'Non-ferrous metals heat',
      value: MitigationSubTypes.NON_FERROUS_METALS_HEAT.valueOf(),
    },
    { label: 'Coke oven gas', value: MitigationSubTypes.COKE_OVEN_GAS.valueOf() },
  ],
  EEService: [
    { label: 'HVAC & lighting', value: MitigationSubTypes.HVAC_LIGHTING.valueOf() },
    { label: 'Air conditioning', value: MitigationSubTypes.AIR_CONDITIONING.valueOf() },
    { label: 'EE new buildings', value: MitigationSubTypes.EE_NEW_BUILDINGS.valueOf() },
    { label: 'Street lighting', value: MitigationSubTypes.STREET_LIGHTING.valueOf() },
    { label: 'Lighting in service', value: MitigationSubTypes.LIGHTING_IN_SERVICE.valueOf() },
    { label: 'Water pumping', value: MitigationSubTypes.WATER_PUMPING.valueOf() },
    { label: 'Water purification', value: MitigationSubTypes.WATER_PURIFICATION.valueOf() },
    { label: 'EE public stoves', value: MitigationSubTypes.EE_PUBLIC_STOVES.valueOf() },
    { label: 'EE public buildings', value: MitigationSubTypes.EE_PUBLIC_BUILDINGS.valueOf() },
    {
      label: 'EE commercial buildings',
      value: MitigationSubTypes.EE_COMMERCIAL_BUILDINGS.valueOf(),
    },
  ],
  EESupplySide: [
    {
      label: 'Single cycle to combined cycle',
      value: MitigationSubTypes.SINGLE_CYCLE_TO_COMBINED_CYCLE.valueOf(),
    },
    { label: 'Cogeneration', value: MitigationSubTypes.COGENERATION.valueOf() },
    { label: 'Co-firing with biomass', value: MitigationSubTypes.CO_FIRING_WITH_BIOMASS.valueOf() },
    {
      label: 'Higher efficiency coal power',
      value: MitigationSubTypes.HIGHER_EFFICIENCY_COAL_POWER.valueOf(),
    },
    {
      label: 'Higher efficiency oil power',
      value: MitigationSubTypes.HIGHER_EFFICIENCY_OIL_POWER.valueOf(),
    },
    {
      label: 'Higher efficiency using waste heat',
      value: MitigationSubTypes.HIGHER_EFFICIENCY_USING_WASTE_HEAT.valueOf(),
    },
    {
      label: 'Power plant rehabilitation',
      value: MitigationSubTypes.POWER_PLANT_REHABILITATION.valueOf(),
    },
    {
      label: 'Higher efficiency steam boiler',
      value: MitigationSubTypes.HIGHER_EFFICIENCY_STEAM_BOILER.valueOf(),
    },
  ],
  EnergyDistribution: [
    { label: 'District heating', value: MitigationSubTypes.DISTRICT_HEATING.valueOf() },
    {
      label: 'Replacement of district heating boilers',
      value: MitigationSubTypes.REPLACEMENT_OF_DISTRICT_HEATING_BOILERS.valueOf(),
    },
    {
      label: 'Connection of Isolated grid',
      value: MitigationSubTypes.CONNECTION_OF_ISOLATED_GRID.valueOf(),
    },
    { label: 'District cooling', value: MitigationSubTypes.DISTRICT_COOLING.valueOf() },
    {
      label: 'Efficient electricity distribution',
      value: MitigationSubTypes.EFFICIENT_ELECTRICITY_DISTRIBUTION.valueOf(),
    },
  ],
  Forestry: [
    { label: 'Afforestation', value: MitigationSubTypes.AFFORESTATION.valueOf() },
    { label: 'Mangroves', value: MitigationSubTypes.MANGROVES.valueOf() },
    { label: 'Agroforestry', value: MitigationSubTypes.AGROFORESTRY.valueOf() },
    { label: 'Reforestation', value: MitigationSubTypes.REFORESTATION.valueOf() },
  ],
  FossilFuel: [
    { label: 'Coal to natural gas', value: MitigationSubTypes.COAL_TO_NATURAL_GAS.valueOf() },
    { label: 'Coal to oil', value: MitigationSubTypes.COAL_TO_OIL.valueOf() },
    { label: 'Lignite to natural gas', value: MitigationSubTypes.LIGNITE_TO_NATURAL_GAS.valueOf() },
    { label: 'New natural gas plant', value: MitigationSubTypes.NEW_NATURAL_GAS_PLANT.valueOf() },
    {
      label: 'New natural gas plant using LNG',
      value: MitigationSubTypes.NEW_NATURAL_GAS_PLANT_USING_LNG.valueOf(),
    },
    { label: 'Oil to electricity', value: MitigationSubTypes.OIL_TO_ELECTRICITY.valueOf() },
    { label: 'Oil to LPG', value: MitigationSubTypes.OIL_TO_LPG.valueOf() },
    { label: 'Oil to natural gas', value: MitigationSubTypes.OIL_TO_NATURAL_GAS.valueOf() },
  ],
  Fugitive: [
    {
      label: 'Oil field flaring reduction',
      value: MitigationSubTypes.OIL_FIELD_FLARING_REDUCTION.valueOf(),
    },
    {
      label: 'Oil and gas processing flaring',
      value: MitigationSubTypes.OIL_AND_GAS_PROCESSING_FLARING.valueOf(),
    },
    { label: 'Natural gas pipelines', value: MitigationSubTypes.NATURAL_GAS_PIPELINES.valueOf() },
    { label: 'Non-hydrocarbon mining', value: MitigationSubTypes.NON_HYDROCARBON_MINING.valueOf() },
    {
      label: 'Spontaneously ignition of coal piles',
      value: MitigationSubTypes.SPONTANEOUSLY_IGNITION_OF_COAL_PILES.valueOf(),
    },
    { label: 'Charcoal production', value: MitigationSubTypes.CHARCOAL_PRODUCTION.valueOf() },
  ],
  Geothermal: [
    { label: 'Geothermal electricity', value: MitigationSubTypes.GEOTHERMAL_ELECTRICITY.valueOf() },
    { label: 'Geothermal heating', value: MitigationSubTypes.GEOTHERMAL_HEATING.valueOf() },
  ],
  HFC_PFCs_SF6: [
    { label: 'HFC23', value: MitigationSubTypes.HFC23.valueOf() },
    { label: 'HFC134a', value: MitigationSubTypes.HFC134A.valueOf() },
    { label: 'PFCs', value: MitigationSubTypes.PFCS.valueOf() },
    { label: 'SF6', value: MitigationSubTypes.SF6.valueOf() },
  ],
  Hydro: [
    { label: 'Run of river', value: MitigationSubTypes.RUN_OF_RIVER.valueOf() },
    { label: 'Existing dam', value: MitigationSubTypes.EXISTING_DAM.valueOf() },
    {
      label: 'Higher efficiency hydro power',
      value: MitigationSubTypes.HIGHER_EFFICIENCY_HYDRO_POWER.valueOf(),
    },
    { label: 'New dam', value: MitigationSubTypes.NEW_DAM.valueOf() },
  ],
  Landfills: [
    { label: 'Landfill flaring', value: MitigationSubTypes.LANDFILL_FLARING.valueOf() },
    { label: 'Landfill power', value: MitigationSubTypes.LANDFILL_POWER.valueOf() },
    { label: 'Combustion of MSW', value: MitigationSubTypes.COMBUSTION_OF_MSW.valueOf() },
    { label: 'Gasification of MSW', value: MitigationSubTypes.GASIFICATION_OF_MSW.valueOf() },
    { label: 'Biogas from MSW', value: MitigationSubTypes.BIOGAS_FROM_MSW.valueOf() },
    { label: 'Landfill aeration', value: MitigationSubTypes.LANDFILL_AERATION.valueOf() },
    {
      label: 'Integrated solid waste management',
      value: MitigationSubTypes.INTEGRATED_SOLID_WASTE_MANAGEMENT.valueOf(),
    },
    {
      label: 'Switch from fossil fuel to piped landfill gas',
      value: MitigationSubTypes.SWITCH_FROM_FOSSIL_FUEL_TO_PIPED_LANDFILL_GAS.valueOf(),
    },
    { label: 'Landfill composting', value: MitigationSubTypes.LANDFILL_COMPOSTING.valueOf() },
  ],
  MethaneAvoidance: [
    { label: 'Manure', value: MitigationSubTypes.MANURE.valueOf() },
    { label: 'Domestic manure', value: MitigationSubTypes.DOMESTIC_MANURE.valueOf() },
    { label: 'Waste water', value: MitigationSubTypes.WASTE_WATER.valueOf() },
    { label: 'Industrial solid waste', value: MitigationSubTypes.INDUSTRIAL_SOLID_WASTE.valueOf() },
    { label: 'Palm oil waste', value: MitigationSubTypes.PALM_OIL_WASTE.valueOf() },
    {
      label: 'Aerobic treatment of waste water',
      value: MitigationSubTypes.AEROBIC_TREATMENT_OF_WASTE_WATER.valueOf(),
    },
    { label: 'Composting', value: MitigationSubTypes.COMPOSTING.valueOf() },
  ],
  N20: [
    { label: 'Adipic acid', value: MitigationSubTypes.ADIPIC_ACID.valueOf() },
    { label: 'Nitric acid', value: MitigationSubTypes.NITRIC_ACID.valueOf() },
    { label: 'Caprolactam', value: MitigationSubTypes.CAPROLACTAM.valueOf() },
  ],
  Solar: [
    {
      label: 'Solar Photovoltaics(PV)',
      value: MitigationSubTypes.SOLAR_PHOTOVOLTAICS_PV.valueOf(),
    },
    {
      label: 'Solar Water pumping (off-grid)',
      value: MitigationSubTypes.SOLAR_WATER_PUMPING_OFF_GRID.valueOf(),
    },
    {
      label: 'Solar Water pumping (on-grid)',
      value: MitigationSubTypes.SOLAR_WATER_PUMPING_ON_GRID.valueOf(),
    },
    {
      label: 'Solar PV water disinfection',
      value: MitigationSubTypes.SOLAR_PV_WATER_DISINFECTION.valueOf(),
    },
    { label: 'Solar thermal power', value: MitigationSubTypes.SOLAR_THERMAL_POWER.valueOf() },
    { label: 'Solar thermal heat', value: MitigationSubTypes.SOLAR_THERMAL_HEAT.valueOf() },
    { label: 'Solar water heating', value: MitigationSubTypes.SOLAR_WATER_HEATING.valueOf() },
    { label: 'Solar cooking', value: MitigationSubTypes.SOLAR_COOKING.valueOf() },
  ],
  Transport: [
    { label: 'Bus Rapid Transit', value: MitigationSubTypes.BUS_RAPID_TRANSIT.valueOf() },
    {
      label: 'Bus Rapid Transit: Green hydrogen',
      value: MitigationSubTypes.BUS_RAPID_TRANSIT_GREEN_HYDROGEN.valueOf(),
    },
    { label: 'Motorbikes', value: MitigationSubTypes.MOTORBIKES.valueOf() },
    {
      label: 'Mode shift: Road to rail',
      value: MitigationSubTypes.MODE_SHIFT_ROAD_TO_RAIL.valueOf(),
    },
    {
      label: 'More efficient train system',
      value: MitigationSubTypes.MORE_EFFICIENT_TRAIN_SYSTEM.valueOf(),
    },
    {
      label: 'More efficient vehicles',
      value: MitigationSubTypes.MORE_EFFICIENT_VEHICLES.valueOf(),
    },
    {
      label: 'Rail: regenerative braking',
      value: MitigationSubTypes.RAIL_REGENERATIVE_BRAKING.valueOf(),
    },
    { label: 'Rail: Green hydrogen', value: MitigationSubTypes.RAIL_GREEN_HYDROGEN.valueOf() },
    {
      label: 'Rail: efficient operation',
      value: MitigationSubTypes.RAIL_EFFICIENT_OPERATION.valueOf(),
    },
    {
      label: 'Metro: efficient operation',
      value: MitigationSubTypes.METRO_EFFICIENT_OPERATION.valueOf(),
    },
    { label: 'Cars: Green hydrogen', value: MitigationSubTypes.CARS_GREEN_HYDROGEN.valueOf() },
    {
      label: 'Cars: efficient operation',
      value: MitigationSubTypes.CARS_EFFICIENT_OPERATION.valueOf(),
    },
    { label: 'Scrapping old vehicles', value: MitigationSubTypes.SCRAPPING_OLD_VEHICLES.valueOf() },
    {
      label: 'Biodiesel for transport',
      value: MitigationSubTypes.BIODIESEL_FOR_TRANSPORT.valueOf(),
    },
    { label: 'Cable cars', value: MitigationSubTypes.CABLE_CARS.valueOf() },
  ],
  Wind: [
    { label: 'Wind', value: MitigationSubTypes.WIND.valueOf() },
    { label: 'Offshore wind', value: MitigationSubTypes.OFFSHORE_WIND.valueOf() },
  ],
  CO2Usage: [
    { label: 'CO2 recycling', value: MitigationSubTypes.CO2_RECYCLING.valueOf() },
    { label: 'CO2 replacement', value: MitigationSubTypes.CO2_REPLACEMENT.valueOf() },
  ],
  Tidal: [{ label: 'Tidal', value: MitigationSubTypes.TIDAL.valueOf() }],
};

export const methodologyOptions = [
  'ACR - Truck Stop Electrification',
  'ACR - Advanced Refrigeration Systems',
  'ACR - Certified Reclaimed HFC Refrigerants, Propellants, and Fire Suppressants',
  'ACR - Destruction of Ozone Depleting Substances and High-GWP Foam',
  'ACR - Destruction of Ozone Depleting Substances from International Sources',
  'ACR - Transition to Advanced Formulation Blowing Agents in Foam Manufacturing and Use',
  'ACR - Afforestation and Reforestation of Degraded Lands',
  'ACR - Avoided Conversion of Grasslands and Shrublands to Crop Production',
  'ACR - Improved Forest Management (IFM) on Canadian Forestlands',
  'ACR - Improved Forest Management (IFM) on Non-Federal U.S. Forestlands',
  'ACR - Improved Forest Management (IFM) on Small Non-Industrial Private Forestlands',
  'ACR - Restoration of California Deltaic and Coastal Wetlands',
  'ACR - Restoration of Pocosin Wetlands',
  'ACR - Carbon Capture and Storage Projects',
  'ACR - Landfill Gas Destruction and Beneficial Use Projects',
  'CAR - Adipic Acid Production',
  'CAR - Biochar',
  'CAR - Canada Grassland',
  'CAR - Coal Mine Methane',
  'CAR - Forest',
  'CAR - Grassland',
  'CAR - Mexico Boiler Efficiency',
  'CAR - Mexico Forest',
  'CAR - Mexico Halocarbon',
  'CAR - Mexico Landfill',
  'CAR - Mexico Livestock',
  'CAR - Mexico Ozone Depleting Substances',
  'CAR - Nitric Acid Production',
  'CAR - Nitrogen Management',
  'CAR - Organic Waste Composting',
  'CAR - Organic Waste Digestion',
  'CAR - Ozone Depleting Substances',
  'CAR - Rice Cultivation',
  'CAR - Soil Enrichment',
  'CAR - Urban Forest Management',
  'CAR - Urban Tree Planting',
  'CAR - U.S. Landfill',
  'CAR - U.S. Livestock',
  'CDM - AM0001',
  'CDM - AM0007',
  'CDM - AM0009',
  'CDM - AM0017',
  'CDM - AM0018',
  'CDM - AM0019',
  'CDM - AM0020',
  'CDM - AM0021',
  'CDM - AM0023',
  'CDM - AM0026',
  'CDM - AM0027',
  'CDM - AM0028',
  'CDM - AM0030',
  'CDM - AM0031',
  'CDM - AM0035',
  'CDM - AM0036',
  'CDM - AM0037',
  'CDM - AM0038',
  'CDM - AM0043',
  'CDM - AM0044',
  'CDM - AM0045',
  'CDM - AM0046',
  'CDM - AM0048',
  'CDM - AM0049',
  'CDM - AM0050',
  'CDM - AM0052',
  'CDM - AM0053',
  'CDM - AM0055',
  'CDM - AM0056',
  'CDM - AM0057',
  'CDM - AM0058',
  'CDM - AM0059',
  'CDM - AM0060',
  'CDM - AM0061',
  'CDM - AM0062',
  'CDM - AM0063',
  'CDM - AM0064',
  'CDM - AM0065',
  'CDM - AM0066',
  'CDM - AM0067',
  'CDM - AM0068',
  'CDM - AM0069',
  'CDM - AM0070',
  'CDM - AM0071',
  'CDM - AM0072',
  'CDM - AM0073',
  'CDM - AM0074',
  'CDM - AM0075',
  'CDM - AM0076',
  'CDM - AM0077',
  'CDM - AM0078',
  'CDM - AM0079',
  'CDM - AM0080',
  'CDM - AM0081',
  'CDM - AM0082',
  'CDM - AM0083',
  'CDM - AM0084',
  'CDM - AM0086',
  'CDM - AM0088',
  'CDM - AM0089',
  'CDM - AM0090',
  'CDM - AM0091',
  'CDM - AM0092',
  'CDM - AM0093',
  'CDM - AM0094',
  'CDM - AM0095',
  'CDM - AM0096',
  'CDM - AM0097',
  'CDM - AM0098',
  'CDM - AM0099',
  'CDM - AM0100',
  'CDM - AM0101',
  'CDM - AM0103',
  'CDM - AM0104',
  'CDM - AM0105',
  'CDM - AM0106',
  'CDM - AM0107',
  'CDM - AM0108',
  'CDM - AM0109',
  'CDM - AM0110',
  'CDM - AM0111',
  'CDM - AM0112',
  'CDM - AM0113',
  'CDM - AM0114',
  'CDM - AM0115',
  'CDM - AM0116',
  'CDM - AM0117',
  'CDM - AM0118',
  'CDM - AM0119',
  'CDM - AM0120',
  'CDM - AM0121',
  'CDM - AM0122',
  'CDM - AMS-I.A.',
  'CDM - AMS-I.B.',
  'CDM - AMS-I.C.',
  'CDM - AMS-I.D.',
  'CDM - AMS-I.E.',
  'CDM - AMS-I.F.',
  'CDM - AMS-I.G.',
  'CDM - AMS-I.H.',
  'CDM - AMS-I.I.',
  'CDM - AMS-I.J.',
  'CDM - AMS-I.K.',
  'CDM - AMS-I.L.',
  'CDM - AMS-I.M.',
  'CDM - AMS-II.A.',
  'CDM - AMS-II.B.',
  'CDM - AMS-II.C.',
  'CDM - AMS-II.D.',
  'CDM - AMS-II.E.',
  'CDM - AMS-II.F.',
  'CDM - AMS-II.G.',
  'CDM - AMS-II.H.',
  'CDM - AMS-II.I.',
  'CDM - AMS-II.J.',
  'CDM - AMS-II.K.',
  'CDM - AMS-II.L.',
  'CDM - AMS-II.M.',
  'CDM - AMS-II.N.',
  'CDM - AMS-II.O.',
  'CDM - AMS-II.P.',
  'CDM - AMS-II.Q.',
  'CDM - AMS-II.R.',
  'CDM - AMS-II.S.',
  'CDM - AMS-II.T.',
  'CDM - AMS-III.A.',
  'CDM - AMS-III.B.',
  'CDM - AMS-III.C.',
  'CDM - AMS-III.D.',
  'CDM - AMS-III.E.',
  'CDM - AMS-III.F.',
  'CDM - AMS-III.G.',
  'CDM - AMS-III.H.',
  'CDM - AMS-III.I.',
  'CDM - AMS-III.J.',
  'CDM - AMS-III.K.',
  'CDM - AMS-III.L.',
  'CDM - AMS-III.M.',
  'CDM - AMS-III.N.',
  'CDM - AMS-III.O.',
  'CDM - AMS-III.P.',
  'CDM - AMS-III.Q.',
  'CDM - AMS-III.R.',
  'CDM - AMS-III.S.',
  'CDM - AMS-III.T.',
  'CDM - AMS-III.U.',
  'CDM - AMS-III.V.',
  'CDM - AMS-III.W.',
  'CDM - AMS-III.X.',
  'CDM - AMS-III.Y.',
  'CDM - AMS-III.Z.',
  'CDM - AMS-III.AA.',
  'CDM - AMS-III.AB.',
  'CDM - AMS-III.AC.',
  'CDM - AMS-III.AD.',
  'CDM - AMS-III.AE.',
  'CDM - AMS-III.AF.',
  'CDM - AMS-III.AG.',
  'CDM - AMS-III.AH.',
  'CDM - AMS-III.AI.',
  'CDM - AMS-III.AJ.',
  'CDM - AMS-III.AK.',
  'CDM - AMS-III.AL.',
  'CDM - AMS-III.AM.',
  'CDM - AMS-III.AN.',
  'CDM - AMS-III.AO.',
  'CDM - AMS-III.AP.',
  'CDM - AMS-III.AQ.',
  'CDM - AMS-III.AR.',
  'CDM - AMS-III.AS.',
  'CDM - AMS-III.AT.',
  'CDM - AMS-III.AU.',
  'CDM - AMS-III.AV.',
  'CDM - AMS-III.AW.',
  'CDM - AMS-III.AX.',
  'CDM - AMS-III.AY.',
  'CDM - AMS-III.BA.',
  'CDM - AMS-III.BB.',
  'CDM - AMS-III.BC.',
  'CDM - AMS-III.BD.',
  'CDM - AMS-III.BE.',
  'CDM - AMS-III.BF.',
  'CDM - AMS-III.BG.',
  'CDM - AMS-III.BH.',
  'CDM - AMS-III.BI.',
  'CDM - AMS-III.BJ.',
  'CDM - AMS-III.BK.',
  'CDM - AMS-III.BL.',
  'CDM - AMS-III.BM.',
  'CDM - AMS-III.BN.',
  'CDM - AMS-III.BO.',
  'CDM - AMS-III.BP.',
  'CDM - AR-AM0014',
  'CDM - AR-AMS0003',
  'CDM - AR-AMS0007',
  'GS - METHODOLOGY FOR METERED & MEASURED ENERGY COOKING DEVICES',
  'GS - METHODOLOGY FOR RETROFIT ENERGY EFFICIENCY MEASURES IN SHIPPING ',
  'GS - SOIL ORGANIC CARBON ACTIVITY MODULE FOR APPLICATION OF ORGANIC SOIL IMPROVERS FROM PULP AND PAPER MILL SLUDGES',
  'GS - REDUCED EMISSIONS FROM COOKING AND HEATING – TECHNOLOGIES AND PRACTICES TO DISPLACE DECENTRALIZED THERMAL ENERGY CONSUMPTION (TPDDTEC)',
  'GS - CARBON SEQUESTRATION THROUGH ACCELERATED CARBONATION OF CONCRETE AGGREGATE ',
  'VCS - VM0001',
  'VCS - VM0002',
  'VCS - VM0003',
  'VCS - VM0004',
  'VCS - VM0005',
  'VCS - VM0006',
  'VCS - VM0007',
  'VCS - VM0008',
  'VCS - VM0009',
  'VCS - VM0010',
  'VCS - VM0011',
  'VCS - VM0012',
  'VCS - VM0013',
  'VCS - VM0014',
  'VCS - VM0015',
  'VCS - VM0016',
  'VCS - VM0017',
  'VCS - VM0018',
  'VCS - VM0019',
  'VCS - VM0020',
  'VCS - VM0021',
  'VCS - VM0022',
  'VCS - VM0023',
  'VCS - VM0024',
  'VCS - VM0025',
  'VCS - VM0026',
  'VCS - VM0027',
  'VCS - VM0028',
  'VCS - VM0029',
  'VCS - VM0030',
  'VCS - VM0031',
  'VCS - VM0032',
  'VCS - VM0033',
  'VCS - VM0034',
  'VCS - VM0035',
  'VCS - VM0036',
  'VCS - VM0037',
  'VCS - VM0038',
  'VCS - VM0039',
  'VCS - VM0040',
  'VCS - VM0041',
  'VCS - VM0042',
  'VCS - VM0043',
  'VCS - VMR000',
  'VCS - VMR006',
  'ID_PM044',
  'ID_PM043',
  'ID_PM042',
  'ID_PM041',
  'ID_PM040',
  'ID_PM039',
  'ID_AM028',
  'ID_AM027',
  'ID_AM026',
  'ID_AM025',
  'ID_AM024',
  'ID_AM023',
  'ID_AM022',
  'ID_AM021',
  'ID_AM020',
  'ID_AM006',
  'VN_PM028',
  'ID_AM019',
  'VN_PM027',
  'ID_AM018',
  'ID_AM007',
  'TH_PM026',
  'VN_PM026',
  'VN_PM025',
  'TH_PM025',
  'ID_AM009',
  'VN_PM024',
  'ID_AM017',
  'TH_PM024',
  'ID_AM016',
  'VN_PM023',
  'TH_PM023',
  'ID_AM015',
  'TH_PM022',
  'VN_AM015',
  'VN_AM009',
  'ID_AM014',
  'TH_PM021',
  'TH_AM017',
  'ID_AM013',
  'VN_AM014',
  'VN_AM013',
  'TH_AM016',
  'VN_AM012',
  'TH_AM015',
  'ID_AM011',
  'TH_AM001',
  'VN_AM011',
  'ID_AM012',
  'VN_AM010',
  'TH_AM014',
  'ID_AM008',
  'VN_AM006',
  'TH_AM013',
  'TH_AM012',
  'VN_AM004',
  'ID_AM005',
  'ID_AM004',
  'TH_AM011',
  'VN_AM003',
  'ID_AM003',
  'VN_AM002',
  'TH_AM010',
  'ID_AM002',
  'TH_AM009',
  'ID_AM010',
  'VN_AM008',
  'TH_AM008',
  'TH_AM005',
  'VN_AM007',
  'TH_AM007',
  'VN_AM001',
  'MM_PM007',
  'TH_AM006',
  'MM_PM006',
  'VN_AM005',
  'KH_AM004',
  'TH_AM002',
  'KH_AM005',
  'BD_PM005',
  'MM_AM005',
  'TH_AM004',
  'LA_AM004',
  'BD_AM001',
  'MN_AM003',
  'MM_AM004',
  'KE_AM003',
  'LA_AM003',
  'MM_AM003',
  'CR_AM003',
  'KH_AM003',
  'PH_PM003',
  'TH_AM003',
  'CL_AM001',
  'ET_AM003',
  'BD_AM003',
  'BD_AM002',
  'ET_AM002',
  'KE_AM002',
  'CR_AM002',
  'LA_AM002',
  'KH_AM002',
  'MV_AM002',
  'MM_AM002',
  'PH_AM002',
  'MN_AM002',
  'CL_AM002',
  'PW_AM001',
  'PH_AM001',
  'KE_AM001',
  'KH_AM001',
  'SA_AM001',
  'MN_AM001',
  'MM_AM001',
  'CR_AM001',
  'ID_AM001',
  'MX_AM001',
  'ET_AM001',
  'LA_AM001',
  'MV_AM001',
  'Puro - BIO CHAR',
];
